import React, { Fragment } from 'react';

import { Action, Actionbar, Paragraph, TextBlock, TextString, Title } from 'ui/components';
import { Fold, Helmet, Layout } from 'ui/partials';

const ImpressumPage = props => (
  <Fragment>
    <Helmet {...props} title="Welcome" />
    <Layout {...props} center>
      <Fold>
        <TextBlock>
          <Title>
            {/* <TextString looks="hcap">Impressum</TextString> */}
            <TextString looks="h1">Impressum</TextString>
          </Title>
          <Paragraph looks="p4" style={{ marginBottom: 0 }}>
            Piotr Fonte Studio
          </Paragraph>
          <Paragraph looks="p4" style={{ marginBottom: 0 }}>
            ul. Pohulanka 4/27
          </Paragraph>
          <Paragraph looks="p4">80-807 Gdańsk, Poland</Paragraph>
          <Paragraph looks="p4" style={{ marginBottom: 0 }}>
            T: +48 505 488 528
          </Paragraph>
          <Paragraph looks="p4">
            E:{' '}
            <Action
              email="studio@piotrf.com"
              headers={{
                subject: 'Question from the website',
                body: 'Hi Piotr,',
              }}
              obfuscated
              plain
              text="studio@piotrf.com"
            />
          </Paragraph>
          <Paragraph looks="p6">VAT-EU PL5782828074</Paragraph>
          <Actionbar>
            <Action button to="/">
              Return home
            </Action>
            <Action
              button
              email="studio@piotrf.com"
              headers={{
                subject: 'Question from the website',
                body: 'Hi Piotr,',
              }}
              obfuscated
              plain
              text="Get in touch"
            />
          </Actionbar>
        </TextBlock>
      </Fold>
    </Layout>
  </Fragment>
);

export default ImpressumPage;
